import React from 'react';
import { Container, Card, CardBody, Row, Col, Table, Button, Modal, ModalHeader, ModalBody, ModalFooter, Spinner, Form, FormGroup, Label, Input, FormFeedback } from "reactstrap";
import moment from 'moment';
import { fetchUsers, fetchNotifications, sendNotifications } from 'api/users-client';

// Api
import { useApi } from 'hooks';

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';

const Users = (props) => {

    const breadcrumbItems = [
        { title: "Dashboard", link: "/dashboard" },
        { title: "İstifadəçilər", link: "#" },
    ];

    const [historyModal, setHistoryModal] = React.useState(false);
    const [notificationModal, setNotificationModal] = React.useState(false);
    const [massNotificationModal, setMassNotificationModal] = React.useState(false);
    const [error, setError] = React.useState({
        title: null,
        body: null,
    });

    const [state, setState] = React.useState({
        recipient: [],
        title: '',
        body: ''
    });

    const { isLoading, data = [], run: fetchUsersRunner } = useApi({
        deferFn: fetchUsers
    })

    const { isLoading: notificationsLoading, data: notifications = [], run: fetchNotificationsRunner } = useApi({
        deferFn: fetchNotifications
    })

    const { isLoading: sendLoadings, run: handleNotification } = useApi({
        deferFn: sendNotifications,
        onResolve: () => {
            if (notificationModal) {
                toggleMassNotificationModal();
            }
            if (massNotificationModal) {
                toggleMassNotificationModal();
            }
        }
    })

    React.useEffect(() => {
        fetchUsersRunner()
    }, [])

    const getUserNotifications = number => {
        toggleHistoryModal();
        fetchNotificationsRunner(number);
    }

    const toggleHistoryModal = () => setHistoryModal(!historyModal);
    const toggleNotificationModal = (number = null) => {        
        if (typeof number !== undefined && number !== '') {
            setState({
                recipient: [number],
                title: '',
                body: '',
            })
        } else {
            setState({
                recipient: [],
                title: '',
                body: '',
            })
            setError({
                title: null,
                body: null,
            })
        }
        setNotificationModal(!notificationModal);
    }
    const toggleMassNotificationModal = () => {
        if (!massNotificationModal) 
        {
            const numbers = data.map(({ number }) => number);
            setState({
                recipient: [numbers],
                title: '',
                body: ''
            })
        } else {
            setState({
                recipient: [],
                title: '',
                body: ''
            })
            setError({
                title: null,
                body: null,
            })
        }
        setMassNotificationModal(!massNotificationModal);
    }

    const handleSendNotification = (type) => {
        if (state.title === undefined || state.title === '') {
            setError(prevState => ({ ...prevState, title: 'empty' }))
        } else if (state.title.length < 10) {
            setError(prevState => ({ ...prevState, title: 'min' }))
        } else if (state.title.length > 150) {
            setError(prevState => ({ ...prevState, title: 'max' }))
        } else if (state.body === undefined || state.body === '') {
            setError(prevState => ({ ...prevState, body: 'empty' }))
        } else if (state.body.length < 10) {
            setError(prevState => ({ ...prevState, body: 'min' }))
        } else if (state.body.length > 400) {
            setError(prevState => ({ ...prevState, body: 'max' }))
        } else {
            handleNotification(state)
        }
    }

    const handleOnChange = (name, value) => {
        setState(prevState => ({ ...prevState, [name]: value }))
        setError(prevState => ({ ...prevState, [name]: null }))
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>

                    <Breadcrumbs title="İstİfadəçİlər" breadcrumbItems={breadcrumbItems} />

                    <Row className='mb-4'>
                        <Col lg={12} md={12} sm={12}>
                            <Button color="danger" onClick={toggleMassNotificationModal} style={{ float: 'right', marginBottom: 15}}>
                                Kütləvi bildiriş göndər
                            </Button>
                        </Col>
                        <Col lg={12} md={12} sm={12}>
                            <Card>
                                <CardBody>
                                    <div className="table-responsive mt-3">
                                        <Table className="table-centered table-bordered datatable dt-responsive nowrap " style={{ borderCollapse: "collapse", borderSpacing: 0, width: "100%" }}>
                                            <thead className="thead-light">
                                                <tr>
                                                    <th style={{ width: "20px" }}>№</th>
                                                    <th>Nömrə</th>
                                                    <th>Son yenilənmə tarixi</th>
                                                    <th style={{ width: "180px" }}></th>
                                                    <th style={{ width: "180px" }}></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {data.map((item, index) => (
                                                    <tr key={index}>
                                                        <td className='text-center'>{index + 1}</td>
                                                        <td>{item.number}</td>
                                                        <td className='text-center'>{moment(item.updated_at).format('DD-MM-YYYY H:s')}</td>
                                                        <td style={{textAlign: 'center'}}>
                                                            <Button type="submit" color="success" size='sm' onClick={() => getUserNotifications(item.number)}>
                                                                {notificationsLoading ? <Spinner color="warning" size="sm" /> : (
                                                                    <i className="ri-file-list-3-line align-middle mr-2"></i>
                                                                )}
                                                                Bildiriş tarixçəsi
                                                            </Button>
                                                        </td>
                                                        <td style={{textAlign: 'center'}}>
                                                            <Button type="submit" color="primary" size='sm' onClick={() => toggleNotificationModal(item.number)}>
                                                                <i className="ri-uninstall-line align-middle mr-2"></i>
                                                                Bildiriş göndər
                                                            </Button>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </Table>

                                        {isLoading ? (<div className="text-center my-3"><i className="mdi mdi-loading mdi-spin font-size-20 align-middle mr-2"></i> Yüklənir </div>) : ''}
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>

            <Modal isOpen={historyModal} toggle={toggleHistoryModal}>
                <ModalHeader toggle={toggleHistoryModal}>Bildiriş tarixçəsi</ModalHeader>
                <ModalBody>
                    {notificationsLoading ? <Spinner color="primary" /> : (
                        <ul style={{ listStyle: 'none', padding: 0, margin: 0 }}>
                            {notifications.map(notifi => (
                                <li style={{ paddingTop: 5, paddingBottom: 10, marginBottom: 10, borderBottomStyle: 'dashed', borderBottomWidth: 1 }}>
                                    <div style={{ fontSize: 13, fontWeight: 600 }}>{moment(notifi.createdAt.date).format('DD.MM.YYYY H:s')}</div>
                                    <div style={{ fontSize: 12 }}>{notifi.message}</div>
                                </li>
                            ))}
                        </ul>
                    )}
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={toggleHistoryModal}>
                        Bağla
                    </Button>
                </ModalFooter>
            </Modal>

            <Modal isOpen={notificationModal} toggle={() => toggleNotificationModal()}>
                <ModalHeader toggle={() => toggleNotificationModal()}>Bildiriş göndər</ModalHeader>
                <ModalBody>
                    <div className='text-center card-subtitle'>{state.recipient && state.recipient[0] ? state.recipient[0] : null} - Nömrəli abunə üçün xüsusi bildiriş.</div>
                    <Form>
                        <FormGroup>
                            <Label for="title">
                                Başlıq
                            </Label>
                            <Input
                                id="title"
                                name="title"
                                placeholder="Bildiriş başlığını daixl edin."
                                type="text"
                                onChange={({ target: { value }}) => handleOnChange('title', value)}
                                value={state.title}
                                invalid={error.title}
                            />
                            <FormFeedback>
                                {error.title === 'empty' ? 'Bildiriş başlığı boş ola bilməz' : error.title === 'min' ? 'Bildiriş başlığı 10 simvoldan az ola bilməz' : error.title === 'max' ? 'Bildiriş başlığı 150 simvoldan çox ola bilməz' : null}
                            </FormFeedback>
                        </FormGroup>                    
                        <FormGroup>
                            <Label for="body">
                                Bildiriş mətni
                            </Label>
                            <Input
                                id="body"
                                name="body"
                                placeholder='Bildiriş mətnini daxil edin.'
                                type="textarea"
                                onChange={({ target: { value }}) => handleOnChange('body', value)}
                                value={state.body}
                                invalid={error.body}
                            />
                            <FormFeedback>
                                {error.body === 'empty' ? 'Bildiriş mətni boş ola bilməz' : error.body === 'min' ? 'Bildiriş mətni 10 simvoldan az ola bilməz' : error.title === 'max' ? 'Bildiriş mətni 400 simvoldan çox ola bilməz' : null}
                            </FormFeedback>
                        </FormGroup>                        
                    </Form>
                </ModalBody>
                <ModalFooter>                    
                    <Button color="secondary" onClick={() => toggleNotificationModal()}>
                        Bağla
                    </Button>
                    <Button color="primary" onClick={() => handleSendNotification(1)}>
                        {sendLoadings ? '...' : 'Göndər'}
                    </Button>
                </ModalFooter>
            </Modal>

            <Modal isOpen={massNotificationModal} toggle={toggleMassNotificationModal}>
                <ModalHeader toggle={toggleMassNotificationModal}>Kütləvi bildiriş göndər</ModalHeader>
                <ModalBody>
                    <Form>
                        <FormGroup>
                            <Label for="title">
                                Başlıq
                            </Label>
                            <Input
                                id="title"
                                name="title"
                                placeholder="Bildiriş başlığını daixl edin."
                                type="text"
                                onChange={({ target: { value }}) => handleOnChange('title', value)}
                                value={state.title}
                                invalid={error.title}
                            />
                            <FormFeedback>
                                {error.title === 'empty' ? 'Bildiriş başlığı boş ola bilməz' : error.title === 'min' ? 'Bildiriş başlığı 10 simvoldan az ola bilməz' : error.title === 'max' ? 'Bildiriş başlığı 150 simvoldan çox ola bilməz' : null}
                            </FormFeedback>
                        </FormGroup>                    
                        <FormGroup>
                            <Label for="body">
                                Bildiriş mətni
                            </Label>
                            <Input
                                id="body"
                                name="body"
                                placeholder='Bildiriş mətnini daxil edin.'
                                type="textarea"
                                onChange={({ target: { value }}) => handleOnChange('body', value)}
                                value={state.body}
                                invalid={error.body}
                            />
                            <FormFeedback>
                                {error.body === 'empty' ? 'Bildiriş mətni boş ola bilməz' : error.body === 'min' ? 'Bildiriş mətni 10 simvoldan az ola bilməz' : error.title === 'max' ? 'Bildiriş mətni 400 simvoldan çox ola bilməz' : null}
                            </FormFeedback>
                        </FormGroup>                        
                    </Form>
                </ModalBody>
                <ModalFooter>                    
                    <Button color="secondary" onClick={toggleMassNotificationModal}>
                        Bağla
                    </Button>
                    <Button color="primary" onClick={() => handleSendNotification(2)}>
                        {sendLoadings ? '...' : 'Göndər'}
                    </Button>
                </ModalFooter>
            </Modal>
        </React.Fragment>
    );
}

export default Users;