import React from 'react';
import { Row, Col, Button, Alert, Container, Label, FormGroup } from "reactstrap";
import { withRouter, Link } from 'react-router-dom';
import { login, handleUserResponse } from 'api/auth-client';
// Api controller
import { useApi } from 'hooks';

// availity-reactstrap-validation
import { AvForm, AvField } from 'availity-reactstrap-validation';

// import images
import logodark from "assets/images/logo-dark.png";

const Login = (props) => {

    const [state, setState] = React.useState({ loginError: false });

    const { isLoading, data, run } = useApi({
        deferFn: login,
        onReject: () => {
            setState({ loginError: 'İstifadəçi adı və ya şifrə yalnışdır.' })
        },
        onResolve: data => {
            const { token } = data || false;

            if (token) {
                handleUserResponse(data, '/dashboard')
            }
        }
    })

    function handleSubmit(event, values) {
        setState({ loginError: false })
        run(values)
    }

    React.useEffect(() => {
        document.body.classList.add("auth-body-bg")
    }, [])

    return (
        <React.Fragment>
            <div>
                <Container fluid className="p-0">
                    <Row className="no-gutters">
                        <Col lg={4}>
                            <div className="authentication-page-content p-4 d-flex align-items-center min-vh-100">
                                <div className="w-100">
                                    <Row className="justify-content-center">
                                        <Col lg={9}>
                                            <div>
                                                <div className="text-center">
                                                    <div>
                                                        <Link to="/" className="logo">
                                                            <img src={logodark} height="60" alt="logo"/>
                                                            <h1>DigiApp Admin</h1>
                                                        </Link>
                                                    </div>
                                                </div>

                                                {state.loginError && state.loginError ? <Alert color="danger">{state.loginError}</Alert> : null}

                                                <div className="p-2 mt-5">
                                                    <AvForm className="form-horizontal" onValidSubmit={handleSubmit} >

                                                        <FormGroup className="auth-form-group-custom mb-4">
                                                            <i className="ri-user-2-line auti-custom-input-icon"></i>
                                                            <Label htmlFor="email">İstifadəçi adı</Label>
                                                            <AvField name="email" type="text" className="form-control" id="email" validate={{ required: true }} placeholder="İstifadəçi adını daxil edin" />
                                                        </FormGroup>

                                                        <FormGroup className="auth-form-group-custom mb-4">
                                                            <i className="ri-lock-2-line auti-custom-input-icon"></i>
                                                            <Label htmlFor="userpassword">Şifrə</Label>
                                                            <AvField name="password" type="password" className="form-control" id="userpassword" validate={{ required: true }} placeholder="Şifrəni daxil edin" />
                                                        </FormGroup>

                                                        <div className="mt-4 text-center">
                                                            <Button color="primary" className="w-md waves-effect waves-light" type="submit">
                                                                {isLoading ? 'Daxil olur...' : 'Daxil ol'}
                                                            </Button>
                                                        </div>

                                                    </AvForm>
                                                </div>

                                                <div className="mt-5 text-center">
                                                    <p>© {new Date().getFullYear()} Digigroup. Crafted with <i className="mdi mdi-heart text-danger"></i></p>
                                                </div>
                                            </div>

                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </Col>
                        <Col lg={8}>
                            <div className="authentication-bg">
                                <div className="bg-overlay"></div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
}


export default withRouter(Login);