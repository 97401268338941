import { client } from 'api';

export const fetchUsers = ([filter = '']) => client(`/app_users`);
export const fetchNotifications = ([number]) => client(`/notifications?number=${number }`);

export const createUsers = ([data]) =>
  client('/app_users/users', { body: data });

export const editUsers = ([id, data]) =>
  client(`/app_users/users/${id}`, { body: data, method: 'put' });

export const deleteUsers = ([id]) =>
  client(`/app_users/users/${id}`, { method: 'delete' });

export const sendNotifications = ([data]) =>
  client(`/notifications`, { body: data, method: 'post' });