import { useAsync } from 'react-async';
import { useErrorHandler } from './useErrorHandler';

export function useApi(options) {
  const result = useAsync(options);

  useErrorHandler(result.error);

  return result;
}
