import { useEffect } from 'react'
import { apiErrorResolver } from 'utils'

export function useErrorHandler(error) {
	let messageText = '';
	useEffect(() => {
		if (error) {
			const { errorMessage } = apiErrorResolver(error);

			switch (errorMessage) {
				case 'invalid_credentials':
					messageText = 'Login və yaxud şifrəniz səhvdir.';
					break;
				default:
					break;
			}

			
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [error]);
}
