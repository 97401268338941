import React, { Component } from 'react';
import { Container, Card, CardBody, Row, Col, Table, UncontrolledTooltip, Alert, Spinner, Label, Modal,ModalHeader, ModalBody, ModalFooter, Button, NavLink, CardText, Nav, NavItem, FormGroup, TabContent, TabPane } from "reactstrap";
import { Link } from "react-router-dom";
import moment from 'moment';
import { AvForm, AvField, AvRadioGroup, AvRadio } from "availity-reactstrap-validation";
import Lightbox from 'react-image-lightbox'

import classnames from "classnames";
// Api
import { useApi } from 'hooks';

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';

import { fetchCampaigns, editCampaign } from 'api/campaigns-client';

import avatar from 'assets/images/users/avatar-1.jpg';

const baseURL = process.env.REACT_APP_API_URL;

const Campaigns = (props) => {

    const breadcrumbItems = [
        { title: "Dashboard", link: "/dashboard" },
        { title: "Kampaniyalar", link: "#" },
    ];

    const [state, setState] = React.useState({
        modal_static: false,
        isAlertOpen: false,
        edit: 0,
        selectedImage: undefined
    });

    const [showImage, setShowImage] = React.useState(false)

    const { isLoading, data = [], run: fetchCampaignsRunner } = useApi({
        deferFn: fetchCampaigns
    })

    React.useEffect(() => {
        fetchCampaignsRunner()
    }, [])

    const isApproved = status => {
        if (+status === 1) {
            return <div className='badge badge-soft-success font-size-14'>Təsdiqlənib</div>
        } else if (+status === 2) {
            return <div className='badge badge-soft-warning font-size-14'>İmtina edilib</div>
        } else {
            return <div className='badge badge-soft-danger font-size-14'>Təsdiq gözləyir</div>
        }
    }

    const getProfileImage = image => {
        if (image) {
            return `${baseURL}/uploads/scans/${image}`;
        } else {
            return avatar;
        }
    }

    const { data: editInfo = [], run: getDriverInfo } = useApi({
        deferFn: fetchCampaigns,
        onResolve: () => {
            setState(prevState => ({ ...prevState, modal_static: true }))
        }
    })

    const handelGetDriverInfo = id => {
        getDriverInfo(`?id=${id}`)
        setState(prevState => ({ ...prevState, edit: id }))
    }

    const { isLoading: updateLoading, run: updateDriver } = useApi({
        deferFn: editCampaign,
        onResolve: () => {
            setState(prevState => ({ ...prevState, isAlertOpen: true }))
            setTimeout(() => {
                setState({ modal_static: false, isAlertOpen: false, edit: 0 })
                fetchCampaignsRunner('?user_type=2')
            }, 1500);
        }
    })

    const [reasonRequired, setReasonRequired] = React.useState(false)

    const handleUpdateDriver = (event, errors, values) => {
        console.log(+values.is_approved === 1 || (+values.is_approved === 2 && typeof values.cancel_reason !== undefined && values.cancel_reason !== ''))
        if (errors.length <= 0 && state.edit) {
            if (+values.is_approved === 1 || (+values.is_approved === 2 && typeof values.cancel_reason !== undefined && values.cancel_reason !== '')) {
                updateDriver(state.edit, { is_approved: +values.is_approved, cancel_reason: values.cancel_reason })
            } else {
                setReasonRequired(true)
            }
        }
    }

    const [activeTab, setActiveTab] = React.useState("1")

    const toggle = tab => {
		if (activeTab !== tab) {
			setActiveTab(tab);
		}
	}

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>

                    <Breadcrumbs title="KAMPANİYALAR" breadcrumbItems={breadcrumbItems} />

                    <Row>
                        <Col lg={{ offset: 10, size: 2 }} className='mb-4'>
                            <Button 
                                type="button" 
                                color="success" 
                                onClick={() => { setState(prevState => ({ ...prevState, modal_static: !state.modal_static, edit: false })); }}
                                className='float-end'          
                            >
                                Yeni kampaniya
                            </Button>
                        </Col>
                        <Col lg={12}>
                            <Card>
                                <CardBody>
                                    <div className="table-responsive mt-3">
                                        <Table className="table-centered table-bordered datatable dt-responsive nowrap " style={{ borderCollapse: "collapse", borderSpacing: 0, width: "100%" }}>
                                            <thead className="thead-light">
                                                <tr>
                                                    <th style={{ width: "20px" }}>№</th>
                                                    <th>Başlıq</th>
                                                    <th>Qısa məzmun</th>
                                                    <th>Şəkil</th>
                                                    <th></th>                                                    
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {data.map((item, index) => (
                                                    <tr key={index}>
                                                        <td className='text-center'>{index + 1}</td>
                                                        <td className='text-center'>{item.name}</td>
                                                        <td className='text-center'>{item.description}</td>
                                                        <td className='text-center'>
                                                            <img src={item.image} alt={item.name} className="rounded avatar-sm" />
                                                        </td>
                                                        <td className='text-center'>
                                                            {/* <Link to="#" className="mr-3 text-primary" id={"eye" + index}  onClick={() => handelGetDriverInfo(item.id)}>
                                                                <i className="mdi mdi-eye font-size-18"></i>
                                                            </Link>
                                                            <UncontrolledTooltip target={"eye" + index} placement="top">
                                                                Ətraflı məlumat
                                                            </UncontrolledTooltip> */}
                                                            <Link to="#" className="mr-3 text-primary" id={"edit" + index}>
                                                                <i className="mdi mdi-pencil font-size-18"></i>
                                                            </Link>
                                                            <UncontrolledTooltip target={"edit" + index} placement="top">
                                                                Redaktə
                                                            </UncontrolledTooltip>
                                                            <Link to="#" className="text-danger" id={"delete" + index}>
                                                                <i className="mdi mdi-trash-can font-size-18"></i>
                                                            </Link>
                                                            <UncontrolledTooltip target={"delete" + index} placement="top">
                                                                Sil
                                                            </UncontrolledTooltip>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </Table>

                                        {isLoading ? (<div className="text-center my-3"><i className="mdi mdi-loading mdi-spin font-size-20 align-middle mr-2"></i> Yüklənir </div>) : ''}
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                    <Modal
                        isOpen={state.modal_static}
                        toggle={null}
                        backdrop="static"
                        centered
                        size="lg"
                    >
                        <ModalHeader toggle={() => { setState(prevState => ({ ...prevState, modal_static: !state.modal_static, edit: false })); setReasonRequired(false); }}>
                            Yeni kampaniya
                        </ModalHeader>
                        <ModalBody>
                            <AvForm onSubmit={handleUpdateDriver}>
                                <Row>
                                    <Col lg={8}>
                                        <Row>
                                            <Col lg={12}>
                                                <Alert color="success" isOpen={state.isAlertOpen}>
                                                    Sürücü uğurla redaktə edildi.
                                                </Alert>
                                            </Col>
                                            <Col lg={6}>
                                                <FormGroup>
                                                    <Label htmlFor="name">Sürücünün adı və soyadı</Label>
                                                    <AvField
                                                        name="name"
                                                        type="text"
                                                        className="form-control"
                                                        id="name"
                                                        placeholder="Sürücünün ad və soyadını daxil edin"
                                                        required
                                                        value={editInfo[0]?.full_name || ''}
                                                        disabled
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col lg={6}>
                                                <FormGroup>
                                                    <Label htmlFor="birthdate">Doğum tarixi</Label>
                                                    <AvField
                                                        name="birthdate"
                                                        type="text"
                                                        className="form-control"
                                                        id="birthdate"
                                                        placeholder="Doğum tarixini daxil edin"
                                                        required
                                                        value={moment(editInfo[0]?.birthdate).format('DD-MM-YYYY') || ''}
                                                        disabled
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col lg={6}>
                                                <FormGroup>
                                                    <Label htmlFor="phone">Telefon nömrəsi</Label>
                                                    <AvField
                                                        name="phone"
                                                        type="text"
                                                        className="form-control"
                                                        id="phone"
                                                        placeholder="Sürücünün nömrəsini daxil edin"
                                                        required
                                                        value={editInfo[0]?.phone_number || ''}
                                                        disabled
                                                    />
                                                </FormGroup>
                                            </Col>

                                            <Col lg={6}>
                                                <FormGroup>
                                                    <Label htmlFor="driving_experience">Sürücülük təcrübəsi</Label>
                                                    <AvField
                                                        name="driving_experience"
                                                        type="driving_experience"
                                                        className="form-control"
                                                        id="driving_experience"
                                                        placeholder="Sürücülük təcrübəsi daxil edin"
                                                        required
                                                        value={editInfo[0]?.driving_experience || ''}
                                                        disabled
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col lg={6}>
                                                <FormGroup>
                                                    <Label htmlFor="gear_type">Sürətlər qutusu</Label>
                                                    <AvField
                                                        name="gear_type"
                                                        type="select"
                                                        className="form-control"
                                                        id="gear_type"
                                                        required
                                                        value={editInfo[0]?.gear_type || '1'}
                                                        placeholder="Sürətlər qutusunu seçin"
                                                        disabled
                                                    >
                                                        <option value="1">Avtomatik</option>
                                                        <option value="2">Mexaniki</option>
                                                        <option value="3">Hər ikisi</option>
                                                    </AvField>
                                                </FormGroup>
                                            </Col>

                                            <Col lg={6}>
                                                <FormGroup>
                                                    <Label htmlFor="covid_status">Covid statusu</Label>
                                                    <AvField
                                                        name="covid_status"
                                                        type="select"
                                                        className="form-control"
                                                        id="covid_status"
                                                        required
                                                        value={editInfo[0]?.covid_status || '1'}
                                                        placeholder="Covid statusunu daxil edin"
                                                        disabled
                                                    >
                                                        <option value="1">Var</option>
                                                        <option value="2">Yoxdur</option>
                                                    </AvField>
                                                </FormGroup>
                                            </Col>
                                        </Row>                                        
                                    </Col>
                                    <Col lg={4}>
                                        <FormGroup>
                                            <Label htmlFor="password">Profil şəkili</Label>
                                            <div>
                                                <img src={`${baseURL}/uploads/scans/${editInfo[0]?.profile_image || ''}`} alt={editInfo[0]?.profile_image || ''} className="rounded responsive_img" />
                                            </div>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg={12}>
                                        <Nav tabs>
                                            <NavItem>
                                                <NavLink
                                                    style={{ cursor: "pointer" }}
                                                    className={classnames({
                                                        active: activeTab === "1"
                                                    })}
                                                    onClick={() => {
                                                        toggle("1");
                                                    }}
                                                >
                                                    Şəxsiyyət vərəqəsi ön
                                                </NavLink>
                                            </NavItem>
                                            <NavItem>
                                                <NavLink
                                                    style={{ cursor: "pointer" }}
                                                    className={classnames({
                                                        active: activeTab === "2"
                                                    })}
                                                    onClick={() => {
                                                        toggle("2");
                                                    }}
                                                >
                                                    Şəxsiyyət vərəqəsi arxa
                                                </NavLink>
                                            </NavItem>
                                            <NavItem>
                                                <NavLink
                                                    style={{ cursor: "pointer" }}
                                                    className={classnames({
                                                        active: activeTab === "3"
                                                    })}
                                                    onClick={() => {
                                                        toggle("3");
                                                    }}
                                                >
                                                    Sürücülük vəsiqəsi ön
                                                </NavLink>
                                            </NavItem>
                                            <NavItem>
                                                <NavLink
                                                    style={{ cursor: "pointer" }}
                                                    className={classnames({
                                                        active: activeTab === "4"
                                                    })}
                                                    onClick={() => {
                                                        toggle("4");
                                                    }}
                                                >
                                                    Sürücülük vəsiqəsi arxa
                                                </NavLink>
                                            </NavItem>
                                        </Nav>


                                        <TabContent activeTab={activeTab}>
                                            <TabPane tabId="1" className="p-3">
                                                <Row>
                                                    <Col sm="12">
                                                        <img src={`${baseURL}/uploads/scans/${editInfo[0]?.id_front || ''}`} alt={editInfo[0]?.id_front || ''} className="rounded responsive_img" />
                                                    </Col>
                                                </Row>
                                            </TabPane>
                                            <TabPane tabId="2" className="p-3">
                                                <Row>
                                                    <Col sm="12">
                                                        <img src={`${baseURL}/uploads/scans/${editInfo[0]?.id_back || ''}`} alt={editInfo[0]?.id_back || ''} className="rounded responsive_img" />
                                                    </Col>
                                                </Row>
                                            </TabPane>
                                            <TabPane tabId="3" className="p-3">
                                                <Row>
                                                    <Col sm="12">
                                                        <img src={`${baseURL}/uploads/scans/${editInfo[0]?.license_front || ''}`} alt={editInfo[0]?.license_front || ''} className="rounded responsive_img" />
                                                    </Col>
                                                </Row>
                                            </TabPane>
                                            <TabPane tabId="4" className="p-3">
                                                <Row>
                                                    <Col sm="12">
                                                        <img src={`${baseURL}/uploads/scans/${editInfo[0]?.license_back || ''}`} alt={editInfo[0]?.license_back || ''} className="rounded responsive_img" />
                                                    </Col>
                                                </Row>
                                            </TabPane>
                                        </TabContent>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg={12}>
                                        <Label htmlFor="cancel_reason">Sürücü statusu</Label>
                                        <AvRadioGroup inline name="is_approved" required>
                                            <AvRadio label="Aktiv et" value="1" />
                                            <AvRadio label="İmtina et" value="2" />
                                        </AvRadioGroup>
                                    </Col>
                                    <Col lg={12}>
                                        <FormGroup>
                                            <Label htmlFor="cancel_reason">İmtina səbəbi</Label>
                                            <AvField
                                                name="cancel_reason"
                                                type="cancel_reason"
                                                className="form-control"
                                                id="cancel_reason"
                                                placeholder="İmtina səbəbini daxil edin"
                                                required={reasonRequired}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row> 
                                <ModalFooter>
                                    <Button type="button" color="light" onClick={() => { setState(prevState => ({ ...prevState, modal_static: !state.modal_static })); setReasonRequired(false); }}>Bağla</Button>
                                    {editInfo[0]?.is_approved !== 1 && (
                                        <Button type="submit" color="success">
                                            {updateLoading && <Spinner className="mr-1" size="sm" color="white" />}
                                            Təsqidlə
                                        </Button>
                                    )}
                                </ModalFooter>

                            </AvForm>

                        </ModalBody>
                    </Modal>
                </Container>
            </div>
        </React.Fragment>
    );
}

export default Campaigns;