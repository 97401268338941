export function apiErrorResolver(error) {
  let errorMessage = 'errorText';
  let errorCode = '500';  

  if (
    error &&
    error.response &&
    error.response.data.type &&
    error.response.data.status &&
    error.response.data.title
  ) {
    errorMessage = error.response.data.type;
    errorCode = error.response.data.status;
  } else if (error && error.response && error.response.data && error.response.data.status > 499) {
    errorMessage = '500';
  }

  return { errorMessage, errorCode };
}
