import { client, cookieAuthKey } from 'api';
import { cookies, windowLocationReplace } from 'utils';

export const resetCookies = () => {
	cookies.remove(cookieAuthKey);
	cookies.remove('dvc');
};

export function handleUserResponse(user, redirectUrl = '/dashboard') {
	const { token } = user;

	if (token) {
		cookies.set(cookieAuthKey, token);
	}

	windowLocationReplace(redirectUrl);
}

function login([data]) {

	const body = {
		...data
	};

	resetCookies();

	return client('/signin', { body });
}

function checkToken({ token }) {
	return client(`/password/recovery/${token}`);
}

function logout() {
	resetCookies();
	window.localStorage.setItem('logout', Date.now());

	windowLocationReplace('/login');
}

function getToken() {
	return cookies.get(cookieAuthKey);
}

export {
	login,
	logout,
	getToken,
	checkToken,
};
