import axios from 'axios';
import { cookies } from 'utils';
import { logout } from './auth-client'

export const cookieAuthKey = '_TKN_';

const headers = { 'content-type': 'application/json' };
const baseURL = process.env.REACT_APP_API_URL;

/**
 * @param {String} endpoint
 */

function client(endpoint, { body, ...customConfig } = {}) {
  const token = cookies.get(cookieAuthKey);

  if (token) {
    headers['X-AUTH-TOKEN'] = token;
  }

  const config = {
    baseURL,
    url: endpoint,
    data: body,
    method: body ? 'POST' : 'GET',
    ...customConfig,
    headers: {
      ...headers,
      ...customConfig.headers,
    },
  };

  return axios.request(config)
    .then(res => res?.data?.data)
    .catch(error => {
      const errorMessage = error?.response?.data;
      if (errorMessage.type === 'auth.token_failed' || error?.response?.data?.error?.title === 'Unauthorized') {
        logout();
      }
      throw error;
    });;
}

export default client;