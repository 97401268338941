import React from 'react';
import { withRouter } from 'react-router-dom';
import { logout } from 'api/auth-client';

const Logout = (props) => {

    React.useEffect(() => {
        logout();
    }, []) 
    
    return (
        <React.Fragment>
            <h1>&nbsp;</h1>
        </React.Fragment>
    );
}

export default withRouter(Logout);