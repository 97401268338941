import React from "react";
import { Container, Row, Col } from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';

//Import Components
import MiniWidgets from "./MiniWidgets";

const Dashboard = () => {
    const breadcrumbItems = [
        { title: "Digigroup", link: "#" },
        { title: "Dashboard", link: "#" },
    ];

    const reports = [
        { icon: "ri-stack-line", title: "Number of Sales", value: "1452", rate: "2.4%", desc: "From previous period" },
        { icon: "ri-store-2-line", title: "Sales Revenue", value: "$ 38452", rate: "2.4%", desc: "From previous period" },
        { icon: "ri-briefcase-4-line", title: "Average Price", value: "$ 15.4", rate: "2.4%", desc: "From previous period" },
    ];

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title="Dashboard" breadcrumbItems={breadcrumbItems} />
                    <Row>
                        <Col xl={12}>
                            <Row>
                                {/* <MiniWidgets reports={reports} /> */}
                            </Row>
                            {/* revenue Analytics */}
                            {/* <RevenueAnalytics/> */}
                        </Col>
                        <Col xl={4}>
                            {/* sales Analytics */}
                            {/* <SalesAnalytics/> */}

                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
}

export default Dashboard;
