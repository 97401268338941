import { client } from 'api';

export const fetchCampaigns = () => client(`/campaigns`);

export const createCampaign = ([data]) =>
  client('/secure/order', { body: data });

export const editCampaign = ([id, status, status_reason, payment_status]) =>
  client(`/secure/order/${id}/status?status=${status}&status_reason=${status_reason}&payment_status=${payment_status}`);

export const deleteCampaign = ([id]) =>
  client(`/secure/order/${id}`, { method: 'delete' });